import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { GenericScanResp } from '@core/interfaces/scan-response.interface';
import { WTDDialogComponent } from '@shared/dialogs/wtd-dialog/wtd-dialog.component';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhatToDoService {
  private panelClasses = ['full-screen-modal'];
  private whatToDoDialog: MatDialogRef<WTDDialogComponent> | undefined = undefined;
  private readonly destroyed$ = new Subject<void>();

  // get dialog instance
  get dialogInstance(): MatDialogRef<WTDDialogComponent> | undefined {
    return this.whatToDoDialog;
  }

  constructor(private dialog: MatDialog, private router: Router) {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.whatToDoDialog?.close();
      }
    });
  }

  public openWTDDialog(scanResponse: GenericScanResp): void {
    if (this.whatToDoDialog && this.whatToDoDialog.componentInstance) {
      // this.whatToDoDialog.close();
      this.whatToDoDialog.componentInstance.data = scanResponse;
    } else {
      this.whatToDoDialog = this.dialog.open(WTDDialogComponent, {
        width: '100%',
        height: '100%',
        data: scanResponse,
        disableClose: true,
        panelClass: this.panelClasses,
        hasBackdrop: false,
        autoFocus: false,
        restoreFocus: true,
      });
    }
  }
}
